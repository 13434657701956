import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback } from 'react';

import { useAnalytics } from '@/hooks/analytics';
import { isServerMaintenanceResponse, RequestUserData, userDataFromBrowser } from '@/utils/apiRequest';
import createReqHeaders from '@/utils/apiRequest/createReqHeaders';
import { isPxResponse, pxTestHeaders } from '@/utils/apiRequest/perimeterx';
import getUrl, { Service } from '@/utils/getUrl';
import { JSONObject } from '@/utils/types';

import { useRecaptcha } from './useRecaptcha';

// Throw an error on any response 400 or above
// We consider these to be fatal errors from the server
axios.interceptors.response.use(
	response => response,
	error => {
		if (error?.status >= 400) {
			return Promise.reject(error);
		}

		return Promise.resolve(error);
	},
);

interface Args {
	endpoint: string;
	method: 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH';
	customHeaders?: { [key: string]: string };
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	body?: any;
	service?: Service;
	userData?: RequestUserData;
	noCache?: boolean;
	timeout?: number;
}

export interface ApiRequest {
	<T = JSONObject>(args: Args): Promise<AxiosResponse<T>>;
}

const noUserFlows = ['subscription/email'];

export const useApi = (): ApiRequest => {
	const showRecaptcha = useRecaptcha();
	const { logEvent } = useAnalytics();

	const apiRequest: ApiRequest = useCallback(
		async args => {
			const {
				endpoint,
				method,
				body,
				customHeaders,
				noCache = false,
				userData = null,
				timeout = 15000,
			} = args;
			const service = args.service ?? 'api';

			const url = `${getUrl(service)}/${endpoint}`;
			const browserUserData = userDataFromBrowser();
			const noCacheHeaders = noCache
				? {
						'Cache-Control': 'no-cache',
						Pragma: 'no-cache',
						Expires: '0',
				  }
				: {};
			const headers: Record<string, string> = createReqHeaders({
				customHeaders: {
					...customHeaders,
					...noCacheHeaders,
					...pxTestHeaders,
				},
				deviceId: userData?.deviceId ?? browserUserData.deviceId,
				userToken: userData?.userToken ?? browserUserData.userToken,
				calmIdentifier: userData?.calmIdentifier ?? browserUserData.calmIdentifier,
				path: userData?.path ?? browserUserData.path,
				userIp: userData?.userIp,
				browserLanguage: userData?.browserLanguage,
			});

			const timestampParams = noCache ? { timestamp: new Date().getTime() } : {};

			const request: AxiosRequestConfig = {
				method,
				url,
				params: { ...timestampParams },
				timeout,
			};
			// Vouchers doesn't want all this nonsense
			if (service !== 'voucher') {
				request.headers = headers;
				request.data = body;
				request.withCredentials = true;
			}

			// Catch-all for email-subscribe flows (that can't have a user token)
			// We handle here in case we use secure customer authentication (which would have some back and forth)
			if (noUserFlows.includes(endpoint) && request.headers?.['x-session-token']) {
				delete request.headers['x-session-token'];
			}

			try {
				const res = await axios(request);
				return res;
			} catch (err) {
				if (isServerMaintenanceResponse(err)) {
					if (typeof window !== 'undefined') {
						window.location.assign('/server-maintenance');
					}
				}
				/**
				 * TODO: this recaptcha stuff is what requires us to make a new closure
				 * whenever logEvent or showRecaptcha change
				 * which themselves are re-made on every change to:
				 * * user (login state / ID)
				 * * privacy settings (HIPAA / GDPR / limited data / etc)
				 * * purchase coupon
				 * So if we could find a way to pull this behavior out of the hook we could
				 * stop closure-busting quite so often.
				 * Thankfully most of this is tied to the user so it will just change once on user auth,
				 * but definitely worth keeping an eye on
				 */
				if (isPxResponse(err)) {
					logEvent({
						eventName: 'Perimeter X : Error : Triggered',
						eventProps: { method, url },
					});
					await showRecaptcha(err?.data);
					return apiRequest(args);
				}
				throw err;
			}
		},
		[logEvent, showRecaptcha],
	);

	return apiRequest;
};
