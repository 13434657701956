import uuid from 'uuid/v4';

import { expiringCalmTokenForTeams, hasSetExpiringCalmTokenForTeams, isTeams } from '../app/msftTeams';
import { getCookie, setCookie } from '../cookies';
import { CALM_TEAMS_DEVICE_ID } from '../privacyCookies';

interface Args {
	deviceId: string | undefined;
	userToken: string | undefined;
	userIp?: string | string[];
	calmIdentifier: string | undefined;
	browserLanguage?: string;
	path?: string;
	cookies?: Record<string, string>;
	userAgent?: string;
	platform?: 'www' | 'msft-teams';
	customHeaders?: Record<string, string | undefined>;
	excludedHeaders?: string[];
}

const Platform = {
	WWW: 'www',
	MSFT_TEAMS: 'msft-teams',
} as const;

// GROOT-1847: Calm Users who access the Calm App through Teams
// should have a unique & persisted msft teams device id associated
// with all their Calm Sessions generated via Calm on MSFT Teams
const getXDeviceId = ({ isUsingTeams, deviceId }: { isUsingTeams: boolean; deviceId: string }): string => {
	if (!isUsingTeams) {
		return deviceId;
	}

	const msftTeamsDeviceIdCookieValue = isUsingTeams ? getCookie(CALM_TEAMS_DEVICE_ID) : undefined;
	const msftTeamsDeviceId: string = msftTeamsDeviceIdCookieValue || uuid();

	if (msftTeamsDeviceId !== msftTeamsDeviceIdCookieValue) {
		setCookie(CALM_TEAMS_DEVICE_ID, msftTeamsDeviceId);
	}

	return msftTeamsDeviceId;
};
export const getPlatform = (isTeams: boolean): 'www' | 'msft-teams' =>
	isTeams ? Platform.MSFT_TEAMS : Platform.WWW;

function createReqHeaders({
	deviceId = '',
	userToken,
	userIp,
	calmIdentifier,
	path,
	platform,
	userAgent,
	cookies,
	browserLanguage = '',
	customHeaders = {},
	excludedHeaders = [],
}: Args): { [key: string]: string } {
	const userIpHeader = Array.isArray(userIp) ? userIp.join(',') : userIp;
	const isUsingTeams = path ? isTeams() : false;
	// For Server-side Rendered pages, it's difficult to determine what Platform
	// we're using unless we explicitly pass it in. So, we should take the `platform`
	// value if provided. Otherwise, attempt to derive it b/c we're likely on a
	// Client-Side Page
	const devicePlatform = platform ? platform : getPlatform(isUsingTeams);
	const xDeviceId = getXDeviceId({ isUsingTeams, deviceId });

	const cookiesString = cookies
		? Object.entries(cookies)
				.map(([key, value]) => `${key}=${value}`)
				.join('; ')
		: null;

	const reqHeaders: Record<string, string> = {
		'x-device-id': xDeviceId,
		'x-device-version': '1.3',
		'x-device-platform': devicePlatform,
		'x-client-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Los_Angeles',
		...(userIp ? { 'X-Forwarded-For': userIpHeader } : {}),
		...(userToken ? { 'x-session-token': userToken } : {}),
		...(browserLanguage ? { 'Accept-Language': browserLanguage } : {}),
		...(path ? { 'x-www-req-from': path } : {}),
		...(cookiesString ? { Cookie: cookiesString } : {}),
		...(calmIdentifier ? { 'x-calm-identifier': calmIdentifier } : {}),
		...customHeaders,
	};

	if (typeof window === 'undefined') {
		reqHeaders['user-agent'] = userAgent ?? 'Calm-API-Client/1.0';
	}

	if (isUsingTeams && hasSetExpiringCalmTokenForTeams()) {
		const teamsReqHeaders: Record<string, string> = {
			...reqHeaders,
			authorization: `Bearer ${expiringCalmTokenForTeams()}`,
		};
		delete teamsReqHeaders['x-session-token'];
		excludedHeaders.forEach(header => {
			if (header in teamsReqHeaders) {
				delete teamsReqHeaders[header as keyof typeof teamsReqHeaders];
			}
		});

		return teamsReqHeaders;
	}

	excludedHeaders.forEach(header => {
		if (header in reqHeaders) {
			delete reqHeaders[header as keyof typeof reqHeaders];
		}
	});

	return reqHeaders;
}

export default createReqHeaders;
