import 'css/fonts.css';

import { AppProps } from 'next/app';
import Head from 'next/head';
import { Provider } from 'react-redux';

import { CalmTheme, DesignSystemProvider } from '@calm-web/design-system';

import SkipToMain from '@/components/accessibility/SkipToMain';
import AnalyticsContextProvider from '@/components/app/layout/AnalyticsContextProvider';
import AppLayout from '@/components/app/layout/AppLayout';
import { AppHeaderProps } from '@/components/app/layout/AppLayout/types';
import AppProviders from '@/components/app/layout/AppProviders';
import OfferBannerProvider from '@/components/app/layout/OfferBannerProvider';
import PrivacyProvider from '@/components/app/layout/PrivacyProvider';
import Devtools from '@/components/layout/Devtools';
import IntlWrapper from '@/components/layout/IntlWrapper';
import LayoutScripts from '@/components/layout/LayoutScripts';
import RecaptchaModal from '@/components/layout/RecaptchaModal';
import ToastNotification from '@/components/ui/ToastNotification';
import AuthContextProvider from '@/context/auth/AuthContextProvider';
import { useStore } from '@/hooks/store/useStore';
import { AppInitialState } from '@/store';

const MyApp = ({
	Component,
	pageProps,
}: AppProps<{
	initialState: AppInitialState;
	appHeaderProps: AppHeaderProps;
	hideAcceptCookiesModal?: boolean;
	customMainId?: string;
}>) => {
	const store = useStore(pageProps.initialState);

	return (
		<>
			<Head>
				<link rel="shortcut icon" type="image/x-icon" href="/_n/favicon.ico" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Head>
			<Provider store={store}>
				<IntlWrapper>
					<DesignSystemProvider theme={CalmTheme}>
						<AnalyticsContextProvider>
							<AuthContextProvider>
								<PrivacyProvider hideAcceptCookiesModal={pageProps?.hideAcceptCookiesModal}>
									<SkipToMain href={pageProps.customMainId} />
									<AppProviders>
										<OfferBannerProvider>
											<AppLayout appHeaderProps={pageProps.appHeaderProps}>
												<LayoutScripts />
												<Component {...pageProps} />
												<Devtools />
												<RecaptchaModal />
												<ToastNotification />
											</AppLayout>
										</OfferBannerProvider>
									</AppProviders>
								</PrivacyProvider>
							</AuthContextProvider>
						</AnalyticsContextProvider>
					</DesignSystemProvider>
				</IntlWrapper>
			</Provider>
		</>
	);
};

export default MyApp;
