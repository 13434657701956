import { ParsedUrlQuery } from 'querystring';

import Purchase from '@/hooks/purchase/purchase';
import { Prices } from '@/store/prices/types';
import { PurchaseParams, RouteSpecificPurchaseParams } from '@/store/purchaseParams/types';
import { User } from '@/store/user/types';
import getParamAsString from '@/utils/getParamsAsString';

import { userCanTrial } from '../subscriptions';

const validPlans = ['monthly', 'yearly', 'lifetime'] as const;
export type ValidPlan = (typeof validPlans)[number];
export const isValidPlan = (value: string): value is ValidPlan => validPlans.includes(value as ValidPlan);

function getPlan(plan: string | null): ValidPlan {
	if (plan && isValidPlan(plan)) {
		return plan;
	}
	return 'yearly';
}

const initCouponValue = ({
	plan,
	query,
	prices,
}: {
	plan: ValidPlan;
	query: ParsedUrlQuery;
	prices: Prices | null;
}): string | null => {
	if (prices?.coupons[plan || 'yearly']) {
		return prices?.coupons[plan || 'yearly']?.id || null;
	}

	return getParamAsString(query.coupon) || null;
};

const getPromotion = (promotion: string | null, isPaidTraffic: boolean) => {
	if (isPaidTraffic) {
		return '7_day_free_trial';
	}

	return promotion;
};

export function initPurchaseParams({
	query,
	prices,
	user,
	routeSpecificPurchaseParams,
}: {
	query: ParsedUrlQuery;
	prices: Prices | null;
	user: User | null;
	routeSpecificPurchaseParams?: RouteSpecificPurchaseParams;
}): PurchaseParams {
	const plan = getPlan(routeSpecificPurchaseParams?.plan || getParamAsString(query.plan));
	const customTrialLength = Number(getParamAsString(query.trialLength));
	const trialLengthInt = Number.isNaN(customTrialLength) ? null : customTrialLength;
	const isPaidTraffic = query.utm_medium === 'paid';

	const initialPurchaseParams: PurchaseParams = {
		plan,
		coupon: routeSpecificPurchaseParams?.coupon ?? initCouponValue({ query, prices, plan }),
		promotion:
			routeSpecificPurchaseParams?.promotion ??
			getPromotion(getParamAsString(query.promotion), isPaidTraffic),
		samsungGUID: routeSpecificPurchaseParams?.samsungGUID ?? null,
		guestPass: routeSpecificPurchaseParams?.guestPass ?? getParamAsString(query.guestPass),
		calmjwt: routeSpecificPurchaseParams?.calmjwt ?? getParamAsString(query.calmjwt),
		is30DayOverride:
			routeSpecificPurchaseParams?.is30DayOverride ?? Boolean(getParamAsString(query.is30DayOverride)),
		giftPrice: routeSpecificPurchaseParams?.giftPrice ?? null,
		isUpdateBillingScreen: routeSpecificPurchaseParams?.isUpdateBillingScreen ?? false,
		trialLengthOverride: trialLengthInt, // Only to be used for initialization, use `purchaseType.duration` for logic
	};

	const purchaseType = prices
		? new Purchase({
				...initialPurchaseParams,
				abTests: {},
				prices,
				user,
				isPaidTraffic,
				freeTrialLength: routeSpecificPurchaseParams?.freeTrialLength ?? null,
				hasFreeTrial: routeSpecificPurchaseParams?.hasFreeTrial ?? null,
				freeTrialLengthUnit: routeSpecificPurchaseParams?.freeTrialLengthUnit ?? null,
				noTrial: !userCanTrial(user) && query.utm_medium !== 'second_free_trial',
		  }).purchaseType
		: null;

	return {
		purchaseType,
		...initialPurchaseParams,
	};
}
